<template>
  <PageContent title="Vendor Sign-Up">
    <div class="box is-8">
      <ValidationObserver ref="form">
        <transition name="fade" mode="out-in" appear>
          <form @submit.prevent="onFormSubmit" class="form">
            <fieldset>
              <div class="form-grid">
                <div class="column is-6">
                  <valid-input
                    label="Vendor Setup Code"
                    type="text"
                    vid="setupcode"
                    placeholder="Setup Code"
                    rules="required|max:20"
                    v-model="formData.vendorSetupCode"
                  />
                </div>
              </div>
            </fieldset>
            <fieldset>
              <ValidationProvider vid="terms" rules="required" v-slot="{ errors }">
                <div class="field">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </fieldset>
            <button type="submit" class="button is-primary is-rounded">
              Submit
            </button>
          </form>
        </transition>
      </ValidationObserver>
    </div>
  </PageContent>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { ValidationError } from '@/services/errors'
import PageContent from '@/containers/PageContent'
import { ToastProgrammatic as Toast } from 'buefy'
import { mapState } from 'vuex'
import registration from '@/services/registration'

import ValidInput from '@/components/inputs/ValidInput'

function notifyError(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-danger'
  })
}

let DEFAULT_FORM_VALUES = {
  vendorSetupCode: ''
}

export default {
  components: {
    PageContent,
    ValidInput,
    ValidationObserver,
    ValidationProvider
  },

  data: function() {
    return {
      isDebug: true,
      formComplete: false,
      formData: {
        ...DEFAULT_FORM_VALUES
      }
    }
  },

  async mounted() {
    this.resetForm()
  },

  watch: {
    hoaId() {
      this.resetForm()
    }
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },

  methods: {
    resetForm() {
      this.formData = { ...DEFAULT_FORM_VALUES }
      if (this.$refs.form) {
        this.$refs.form.reset()
      }
    },

    async onFormSubmit() {
      if (this.isDebug == true) console.debug('...onFormSubmit')
      try {
        if (
          !this.formData ||
          this.formData == undefined ||
          !this.formData.vendorSetupCode ||
          this.formData.vendorSetupCode == undefined
        ) {
          notifyError(`Please enter a proper sign-up code for proper processing.`)
          return
        }

        try {
          let results = await registration.ValidateVendorSignupCodeWithSuccess(
            this.formData.vendorSetupCode
          )

          if (results && results != undefined) {
            if (this.isDebug == true) console.debug('results...' + JSON.stringify(results))

            if (results == false) {
              notifyError(`The vendor sign-up code is not valid.`)
              return
            }
          } else {
            notifyError(`The vendor sign-up code is not valid.`)
            return
          }
        } catch (e) {
          notifyError(`The vendor sign-up code is not valid.`)
          return
        }

        // redirect to registration with sign-up code
        this.formComplete = true
        this.$router.push({
          name: 'registerVendor',
          params: { code: this.formData.vendorSetupCode }
        })
      } catch (e) {
        // If this is a validation error, get the details for each field
        if (e instanceof ValidationError) {
          this.$refs.form.setErrors(e.fields)
        }
        notifyError(e)
      }
      this.loading = false
    }
  }
}
</script>
